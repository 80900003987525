import React from 'react'
import { Button } from '../../../design'
import { navigate } from 'gatsby'
import './IntroStep.scss'

type IntroStepProps = {
  introText: string
  onStepComplete: () => void
}

const IntroStep = ({ introText, onStepComplete }: IntroStepProps) => {
  const getContent = () => {
    return (
      <>
        <h3 className="greeting">{introText}</h3>
        <Button className="start-button" buttonSize="large" onClick={() => onStepComplete()}>
          Поехали!
        </Button>
        <br />
        <Button buttonType="link" onClick={() => navigate('/categories')}>
          ← Назад к категориям вопросов
        </Button>
      </>
    )
  }

  return (
    <div className="section-intro">
      <div className="section-inner section-inner--m vertical-indent--xs">{getContent()}</div>
    </div>
  )
}

export default IntroStep
