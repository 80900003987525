const springFadeInConfig = {
  to: { opacity: 1 },
  from: { opacity: 0 },
  reset: true,
  config: { duration: 300 },
}

const springFadeInConfigWithoutReset = {
  to: { opacity: 1 },
  from: { opacity: 0 },
  config: { duration: 300 },
}

export { springFadeInConfig, springFadeInConfigWithoutReset }
