import React from 'react'
import ReactPlayer from 'react-player'
import { Button } from '../../../design'
import { useSpring, animated } from 'react-spring'
import { Question } from '../../../fixtures/questions'

type AnswerPlayerProps = {
  question: Question
  answerBlobUrl: string
  mimeType: string
  onNextQuestion: () => void
  onAnswerAgain: () => void
}

const AnswerPlayer = ({ question, answerBlobUrl, mimeType, onNextQuestion, onAnswerAgain }: AnswerPlayerProps) => {
  const springProps = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    reset: true,
    config: { duration: 500 },
  })

  return (
    <div className="section-exercise__answer-player section-inner section-inner--s vertical-indent--xs">
      <animated.div style={springProps}>
        <h3 className="question">{question.question}</h3>
        <div className="player-wrapper">
          <ReactPlayer
            url={[{ src: answerBlobUrl, type: mimeType }]}
            className="react-player"
            width="100%"
            height="100%"
            controls
            webkit-playsinline="true"
            playsinline
          />
        </div>
        <div className="buttons-container">
          <Button className="answer-again-button" buttonType="link" onClick={onAnswerAgain}>
            Перезаписать ответ
          </Button>
          <Button buttonSize="large" onClick={onNextQuestion}>
            Дальше
          </Button>
        </div>
      </animated.div>
    </div>
  )
}

export default AnswerPlayer
