import React, { useState } from 'react'
import InfoStep from './Info'
import AskPermissions from './AskPermissions'
import './PermissionsStep.scss'

type PermissionsStepProps = {
  onStepComplete: () => void
}

const PermissionsStep = ({ onStepComplete }: PermissionsStepProps) => {
  const [infoStepFinished, setInfoStepFinished] = useState(false)

  const onInfoStepFinished = () => {
    setInfoStepFinished(true)
  }

  return (
    <div className="section-permissions section-inner section-inner--s vertical-indent--xs">
      {infoStepFinished ? (
        <AskPermissions onStepComplete={onStepComplete} />
      ) : (
        <InfoStep onStepComplete={onInfoStepFinished} />
      )}
    </div>
  )
}

export default PermissionsStep
