import React, { useRef, useEffect, useState } from 'react'
import classNames from 'classnames'
import Webcam from 'react-webcam'
import ReactPlayer from 'react-player'
import mediaPermissionsVideo from '../../../videos/media-permissions.mp4'
import mediaPermissionsFixVideo from '../../../videos/media-permissions-fix.mp4'
import { Button } from '../../../design'
import { removeHasMediaPermissions, setHasMediaPermissions } from '../../../utils/storage'
import { springFadeInConfigWithoutReset } from '../../../utils/animation'
import { sendAnalyticsEvent } from '../../../utils/ga'

type AskPermissionsStep = {
  onStepComplete: () => void
}

const AskPermissionsStep = ({ onStepComplete }: AskPermissionsStep) => {
  const webcamRef = useRef(null)
  const [permissionsAsked, setPermissionsAsked] = useState(false)
  const [mediaSuccess, setMediaSuccess] = useState(false)
  const [videoIsPlaying, setVideoIsPlaying] = useState(false)
  const [mediaError, setMediaError] = useState(false)

  const resultClass = classNames('result', { 'show-properly': mediaSuccess || mediaError })

  useEffect(() => {
    const onVideoPlaying = () => {
      setVideoIsPlaying(true)
    }

    if (webcamRef?.current?.video) {
      webcamRef?.current?.video?.addEventListener('playing', onVideoPlaying)
    }

    return () => {
      if (webcamRef?.current?.video) {
        webcamRef?.current?.video?.removeEventListener('playing', onVideoPlaying)
      }
    }
  }, [webcamRef?.current?.video])

  const onUserMediaError = () => {
    setMediaError(true)
    removeHasMediaPermissions()
  }

  const onUserMedia = () => {
    setMediaSuccess(true)
    setHasMediaPermissions()
  }

  const onRefreshPage = () => {
    sendAnalyticsEvent('click', { label: 'permissions-refresh-page' })
    window.location.reload()
  }

  const getAfterPermissionsText = () => {
    if (mediaError) {
      return (
        <p className="description">
          Не удалось получить разрешение на использование камеры и микрофона. Без этих разрешений работа нашего сервиса
          невозможна. Попробуйте выполнить инструкции изображенные на видео и обновите страницу.
        </p>
      )
    }

    return (
      <h3 className="description description--centered">
        {videoIsPlaying ? 'Отлично! Теперь все готово для начала работы.' : 'Еще секунду...'}
      </h3>
    )
  }

  return (
    <div className="section-permissions__ask">
      {permissionsAsked && (
        <div className={resultClass}>
          {mediaError ? (
            <div className="player-wrapper">
              <ReactPlayer
                url={mediaPermissionsFixVideo}
                className="react-player"
                width="100%"
                height="100%"
                playing
                loop
                webkit-playsinline="true"
                playsinline
              />
            </div>
          ) : (
            <div className="camera-preview-wrapper">
              <Webcam
                mirrored={true}
                className="webcam-preview"
                ref={webcamRef}
                height="200px"
                onUserMedia={onUserMedia}
                onUserMediaError={onUserMediaError}
              />
            </div>
          )}
          {getAfterPermissionsText()}
          <div className="button-wrapper">
            {mediaError ? (
              <Button buttonSize="large" onClick={onRefreshPage}>
                Обновить страницу
              </Button>
            ) : (
              <Button buttonSize="large" onClick={onStepComplete} disabled={!videoIsPlaying}>
                Вперед!
              </Button>
            )}
          </div>
        </div>
      )}
      {!mediaSuccess && !mediaError && (
        <div className="instructions">
          <p className="description">{`После нажатия кнопки "Дать разрешение" браузер предложит Вам разрешить использовать камеру и микрофон на нашем сайте.`}</p>
          <p className="description description--with-margin-bottom">Это выглядит примерно так:</p>
          <div className="player-wrapper">
            <ReactPlayer
              url={mediaPermissionsVideo}
              className="react-player"
              width="100%"
              height="100%"
              playing
              loop
              webkit-playsinline="true"
              playsinline
            />
          </div>
          <div className="button-wrapper">
            <Button buttonSize="large" onClick={() => setPermissionsAsked(true)} disabled={permissionsAsked}>
              Дать разрешение
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default AskPermissionsStep
