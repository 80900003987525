import React, { useEffect, useState } from 'react'
import { useSpring, animated } from 'react-spring'
import { Button } from '../../../design'
import { springFadeInConfig } from '../../../utils/animation'
import AnswerPlayer from './AnswerPlayer'
import AnswerRecorder from './AnswerRecorder'
import { Question } from '../../../fixtures/questions'
import './ExerciseStep.scss'

type ExerciseStepProps = {
  question: Question
  noMoreQuestions: boolean
  mimeType: string
  onAddAnswer: (question: Question, blob: Blob, duration: number) => void
  onNoMoreQuestions: () => void
  onSkipQuestion: () => void
  onRemoveLastAnswer: () => void
  onQuestionComplete: () => void
}

const ExerciseStep = ({
  question,
  noMoreQuestions,
  mimeType,
  onAddAnswer,
  onNoMoreQuestions,
  onSkipQuestion,
  onRemoveLastAnswer,
  onQuestionComplete,
}: ExerciseStepProps) => {
  const [isRecordingAnswer, setIsRecordingAnswer] = useState(true)
  const [answerBlobUrl, setAnswerBlobUrl] = useState<string | undefined>()
  const springProps = useSpring(springFadeInConfig)

  useEffect(() => {
    return () => {
      if (answerBlobUrl) {
        window.URL.revokeObjectURL(answerBlobUrl)
      }
    }
  }, [])

  const onNextQuestion = () => {
    setAnswerBlobUrl(null)
    setIsRecordingAnswer(true)
    window.URL.revokeObjectURL(answerBlobUrl)
    onQuestionComplete()
  }

  const onAnswerAgain = () => {
    onRemoveLastAnswer()
    setAnswerBlobUrl(null)
    setIsRecordingAnswer(true)
    window.URL.revokeObjectURL(answerBlobUrl)
  }

  const onRecordingComplete = (recordedChunks: Blob[], duration: number) => {
    const blob = new Blob(recordedChunks, {
      type: mimeType,
    })
    onAddAnswer(question, blob, duration)
    const url = URL.createObjectURL(blob)
    setAnswerBlobUrl(url)
    setIsRecordingAnswer(false)
  }

  if (noMoreQuestions) {
    return (
      <div className="section-exercise__no-more-questions section-inner section-inner--m vertical-indent--xs">
        <animated.div style={springProps}>
          <h1>В данной категории больше нет вопросов</h1>
          <Button buttonSize="large" onClick={onNoMoreQuestions}>
            Посмотреть результаты интервью
          </Button>
        </animated.div>
      </div>
    )
  }

  return (
    <>
      {isRecordingAnswer && (
        <AnswerRecorder
          question={question}
          mimeType={mimeType}
          onRecordingComplete={onRecordingComplete}
          onSkipQuestion={onSkipQuestion}
        />
      )}
      {!isRecordingAnswer && (
        <AnswerPlayer
          question={question}
          answerBlobUrl={answerBlobUrl}
          mimeType={mimeType}
          onAnswerAgain={onAnswerAgain}
          onNextQuestion={onNextQuestion}
        />
      )}
    </>
  )
}

export default ExerciseStep
