import React from 'react'
import { navigate } from 'gatsby'
import { Button } from '../../../design'
import './BlockerInfo.scss'

type BlockerInfoProps = {
  noCamera: boolean
  noMicrophone: boolean
  unsupportedBrowser: boolean
}

const BlockerInfo = ({ noCamera, noMicrophone, unsupportedBrowser }: BlockerInfoProps) => {
  const getErrorMessage = () => {
    if (noCamera || noMicrophone) {
      return (
        <>
          <h2 className="title">К сожалению, работа с тренажером интервью на Вашем устройстве невозможна</h2>
          <p className="body-large description">
            Причина: {noCamera ? 'не удалось обнаружить камеру' : 'не удалось обнаружить микрофон'}
          </p>
          <p className="instructions">
            Если {noCamera ? 'камера' : 'микрофон'} присутствует, то попробуйте перезапустить браузер. Если и это не
            помогло, то перезагрузите компьютер.
          </p>
        </>
      )
    } else if (unsupportedBrowser) {
      return (
        <>
          <h2 className="title">К сожалению, работа с тренажером интервью в Вашем браузере невозможна</h2>
          <p className="body-large description">Попробуйте новую версию одного из следующих браузеров: </p>
          <ul className="browser-list">
            <li>
              <a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">
                Google Chrome
              </a>
            </li>
            <li>
              <a href="https://www.microsoft.com/edge" target="_blank" rel="noopener noreferrer">
                Microsoft Edge
              </a>
            </li>
            <li>
              <a href="https://www.mozilla.org/firefox/new/" target="_blank" rel="noopener noreferrer">
                Mozilla Firefox
              </a>
            </li>
            <li>
              <a href="https://www.apple.com/safari/" target="_blank" rel="noopener noreferrer">
                Safari (для устройств Apple)
              </a>
            </li>
          </ul>
        </>
      )
    }
  }

  return (
    <div className="section-blocker-info section-inner section-inner--s vertical-indent--xs">
      {getErrorMessage()}
      <Button buttonSize="large" onClick={() => navigate('/')}>
        Вернуться на главную страницу
      </Button>
    </div>
  )
}

export default BlockerInfo
