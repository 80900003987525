const detectDevices = async () => {
  let hasCamera = false
  let hasMicrophone = false

  let md = navigator.mediaDevices

  if (md && md.enumerateDevices) {
    const devices = await md.enumerateDevices()
    console.log(JSON.stringify(devices))

    hasCamera = devices.some(device => 'videoinput' === device.kind)
    hasMicrophone = devices.some(device => 'audioinput' === device.kind)
  }

  return {
    hasCamera,
    hasMicrophone,
  }
}

export default detectDevices
