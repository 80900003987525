export type Question = {
  question: string
}

export type QuestionSet = {
  categoryCode: string
  categoryTitle: string
  introText: string
  questions: Question[]
  randomOrder?: boolean
}

const pozner: QuestionSet = {
  categoryCode: 'pozner',
  categoryTitle: 'Познер',
  randomOrder: true,
  introText: 'Здравствуйте! У моего хорошего друга Марселя Пруста есть к Вам несколько вопросов.',
  questions: [
    { question: 'Оказавшись перед Богом, что Вы ему скажете?' },
    {
      question: 'Если бы Вы могли пообщаться с любым из когда-либо живших людей, кто бы это был?',
    },
    { question: 'Как Вы относитесь к богатству?' },
    {
      question: 'Если бы Вы могли купить одну единственную вещь, что бы это было?',
    },
    { question: 'Кто был Вашим любимым героем детства?' },
    { question: 'Счастье - это?' },
    { question: 'Несчастье - это?' },
    { question: 'Какой грех Вы прощаете проще всего?' },
    { question: 'Какой грех Вы никогда не простите?' },
    {
      question: 'Назовите три слова которые Вы хотели чтобы говорили о вас?',
    },
    { question: 'Вы поймали золотую рыбку. Ваши три желания?' },
    { question: 'Чего Вы боитесь больше всего?' },
    { question: 'В каких случаях Вы лжете?' },
    {
      question: 'Какие три книжки Вы взяли бы с собой на необитаемый остров?',
    },
    { question: 'Карьера или принципы?' },
    { question: 'У Вас есть кумир? Кто он?' },
    { question: 'Какого таланта у Вас нет, о котором Вы сожалеете?' },
    {
      question: 'По двадцатибальной шкале какую оценку Вы бы себе дали?',
    },
    { question: 'Что бы Вы изменили в себе, если бы могли? ' },
    { question: 'Какие добродетели Вы цените больше всего?' },
    { question: 'Если не собой, то кем Вам хотелось бы быть?' },
    { question: 'Кто Ваши любимые литературные персонажи?' },
    { question: 'Ваши любимые герои в реальной жизни?' },
    { question: 'Ваши любимые героини в реальной жизни?' },
    { question: 'Ваши любимые литературные женские персонажи?' },
    { question: 'Ваше любимое блюдо, напиток?' },
    { question: 'Ваши любимые имена?' },
    { question: 'К чему Вы испытываете отвращение?' },
    {
      question: 'Какие исторические личности вызывают Вашу наибольшую антипатию?',
    },
    { question: 'Ваше любимое изречение?' },
    { question: 'Ваша самая характерная черта?' },
    {
      question: 'Качества, которые Вы больше всего цените в мужчине?',
    },
    {
      question: 'Качества, которые Вы больше всего цените в женщине?',
    },
    { question: 'Что Вы больше всего цените в Ваших друзьях?' },
    { question: 'Что является Вашим главным недостатком?' },
    { question: 'Какое Ваше любимое занятие?' },
    { question: 'Каким Вы хотели бы быть?' },
    { question: 'В какой стране Вам хотелось бы жить?' },
    { question: 'Ваш любимый цвет?' },
    { question: 'Ваш любимый цветок?' },
    { question: 'Ваша любимая птица?' },
    { question: 'Ваши любимые писатели?' },
    { question: 'Ваши любимые поэты?' },
    { question: 'Ваши любимые композиторы?' },
    { question: 'Ваши любимые художники?' },
    { question: 'Ваша любимая героиня в истории?' },
    { question: 'Что Вы больше всего ненавидите?' },
    { question: 'Исторические персонажи, которых Вы презираете?' },
    {
      question: 'Какой момент в военной истории Вы цените больше всего?',
    },
    { question: 'Реформа, которую Вы цените особенно высоко?' },
    { question: 'Способность, которой Вам хотелось бы обладать?' },
    { question: 'Как Вы хотели бы умереть?' },
    { question: 'Ваше состояние духа в настоящий момент?' },
    { question: 'Ваш девиз?' },
  ],
}

const dud: QuestionSet = {
  categoryCode: 'dud',
  categoryTitle: 'Дудь',
  randomOrder: true,
  introText: 'Всем привет! К этому интервью мы готовились ровно год. И вот наконец-то мы здесь. Фух, вперед!',
  questions: [
    { question: 'Сколько ты зарабатываешь?' },
    { question: 'Оказавшишь перед Путиным, что ты ему скажешь?' },
    { question: 'В чем сила?' },
    { question: 'Что будет с Россией через три года?' },
    { question: 'Что тебя не устраивает в России?' },
    { question: 'Телевизор - это зло?' },
    { question: 'Ты обсуждаешь политику с близкими?' },
    { question: '"Что Было Дальше" - это круто?' },
    { question: 'Три лучших актера прямо сейчас?' },
    { question: 'Три лучших актрисы прямо сейчас?' },
    { question: 'Три лучших фильма за всю историю?' },
    { question: 'Три лучших группы за всю историю?' },
    { question: 'Три твоих лучших музыкальных открытия за последнее время?' },
    { question: 'Почему Россия бухает?' },
    { question: 'Ты веришь в Бога?' },
    { question: 'Путин - красавчик?' },
    { question: 'Деньги или честь?' },
    { question: 'Деньги или принципы?' },
    { question: 'У тебя есть объяснение почему народ фанатеет от Сталина?' },
    { question: 'Можно ли критиковать Россию, когда живешь в Европе?' },
    { question: 'В чем смысл жизни?' },
    { question: 'Москва - это Европа или Азия?' },
  ],
}

const makarena: QuestionSet = {
  categoryCode: 'makarena',
  categoryTitle: 'Макарена',
  randomOrder: true,
  introText: 'Привет! Попробуем ответить на вопросы, которые встречаются на канале "Макарена" Карена Адамяна.',
  questions: [
    { question: 'Как стать профи в своей сфере?' },
    { question: 'Кто является MVP (самым ценным человеком) планеты Земля?' },
    { question: 'Три твоих любимых бренда одежды?' },
    { question: 'Дудь или Ургант?' },
    { question: 'Билли Айлиш. В чем секрет ее успеха?' },
    { question: 'Самый смешной юморист в России?' },
    { question: 'Самая дорогая вещь в твоем гардеробе?' },
    { question: 'Что тебя раздражает в людях?' },
    { question: 'Самый крутой номер в твоем телефоне?' },
    { question: 'Кого ты смотришь на Ютубе?' },
    { question: 'С кем бы ты махнулась/махнулся жизнями на один день?' },
    { question: 'Что тебя раздражает в себе?' },
    { question: 'Что тебе нравится в твоей внешности?' },
    { question: 'Лучшая идея для первого свидания?' },
    { question: 'Последняя философская тема, над которой ты думал/думала?' },
    { question: 'Совет себе 20-ти летнему?' },
    { question: 'Твоя любимая песня на русском языке?' },
    { question: 'Твой талант, о котором мало кто знает?' },
    { question: 'Читал/читала ли ты когда-нибудь чужую переписку?' },
    { question: 'Твой совет каждому отцу?' },
    { question: 'Твой совет каждой матери?' },
    { question: 'Кого бы из исторических личностей ты оживил/оживила?' },
    { question: 'Где бы ты хотел/хотела пожить?' },
    { question: 'Это нормально, когда девушка платит на свиданиях?' },
    { question: 'Простить измену - да или нет?' },
    { question: 'Что тебе не дает заснуть по ночам?' },
    { question: 'Когда девушка зарабатывает больше парня - это проблема?' },
    { question: 'На что ты тратишь слишком много денег?' },
  ],
}

const russkieNorm: QuestionSet = {
  categoryCode: 'russkie-norm',
  categoryTitle: 'Русские норм!',
  randomOrder: true,
  introText:
    'Привет! Попробуем ответить на вопросы, которые встречаются на канале "Русские норм!" с Елизаветой Осетинской.',
  questions: [
    { question: 'Предпринимательство — про что это?' },
    { question: 'У кого нужно учиться?' },
    { question: 'Книга, которая изменила твою жизнь?' },
    { question: 'Нужно ли тратить деньги на благотворительность?' },
    { question: 'Можно ли быть свободным человеком в несвободной стране?' },
    { question: 'Лучше быть бедным и здоровым или богатым, но больным?' },
    { question: 'Дешево, быстро и хорошо. Какие два качества Вы выберите?' },
    { question: 'Новости из РБК или Медузы?' },
    { question: 'Продукты из Азбуки Вкуса или Пятерочки?' },
    { question: 'Интернету есть куда расти?' },
    { question: 'Ваше идеальное место для жизни?' },
    { question: 'Если бы Вы могли изменить одну вещь в жизни, то какую?' },
    { question: 'Риск или продуманная стратегия?' },
    { question: 'Хотели ли бы Вы чтобы о Вас написали книгу, и если да, то кто был бы автором?' },
    { question: 'Можно ли построить большую компанию без денег инвесторов?' },
    { question: 'Зачем успешному человеку Тони Роббинс?' },
    {
      question:
        'Как объяснить Black Lives Matter человеку, который никогда об этом не слышал? И нужно ли это движениие?',
    },
  ],
}

const aPogovorit: QuestionSet = {
  categoryCode: 'a-pogovorit',
  categoryTitle: 'А поговорить?',
  randomOrder: true,
  introText: 'Привет! Попробуем ответить на вопросы, которые встречаются на канале "А поговорить?" с Ириной Шихман.',
  questions: [
    { question: 'Что ты думаешь про пластические операции? Больше - лучше?' },
    { question: 'Есть ли в творчестве место для политики? Например, в театре?' },
    { question: 'Что качать - попу или мозги?' },
    { question: 'Кто в России сильный пол?' },
    { question: 'Должен ли художник быть голодным?' },
    { question: 'Почему наш народ так любит ностальгировать?' },
    { question: 'Что ты больше всего любишь в себе?' },
    { question: 'Почему многие девушки хотят стать моделями?' },
    { question: 'Почему подростки стали интересоваться политикой?' },
    { question: 'Патриотизму можно научить?' },
    { question: 'Любая идеология - это плохо?' },
    { question: 'Можно и нужно ли прощать измены?' },
    { question: 'Что помогает сохранить отношения? Разные номера и кровати - это нормально?' },
    { question: 'В чем измеряется успех?' },
    { question: 'Балет - это спорт или искусство?' },
    { question: 'Чем Санкт-Петербург хуже Москвы? И хуже ли?' },
    { question: 'Почему русских девушек легко узнают за границей по одежде?' },
    { question: 'Быть одинокой или одиноким - это классно?' },
    { question: 'Кого ты хотела/хотел бы видеть в качестве президента России, если не Путина?' },
  ],
}

const guriev: QuestionSet = {
  categoryCode: 'guriev',
  categoryTitle: 'Гуриев',
  randomOrder: true,
  introText:
    'Привет! Попробуем ответить на вопросы, которые встречаются на канале Сергея Гуриева в программе "Что (же) делать?".',
  questions: [
    {
      question: 'Какие реформы нужно делать в первую очередь?',
    },
    {
      question: 'Можно ли решить проблемы экономики без реформы судебной и правоохранительной системы ?',
    },
    { question: 'Когда Россия сможет догнать Центральную и Восточную Европу?' },
    { question: 'Как распределить деньги между центром, регионами и муниципалитетами?' },
    { question: 'В чем состоит российская национальная идентичность?' },
    { question: 'Является ли Россия европейской страной?' },
    { question: 'Что делать с недоверием государству?' },
    { question: 'Почему россияне столь положительно восприняли «крымнаш»? Изменилось ли восприятие сейчас?' },
    { question: 'У каких стран России полезно учиться и чему?' },
    { question: 'Сколько полицейских нужно России?' },
    { question: 'Какие у России есть возможности для развития зеленой энергетики?' },
    { question: 'В чем проблемы российской энергетики?' },
    { question: 'В чем заключаются национальные интересы и цели внешней политики России?' },
    { question: 'Какими инструментами внешней политики располагает Россия?' },
    { question: 'Чего не хватает российской дипломатии?' },
    { question: 'Как сделать Россию более открытой страной?' },
    { question: 'Нужна ли России евроинтеграция и как реагировать на сближение с Европой бывших советских республик?' },
    { question: 'Что можно было бы посоветовать российским политикам?' },
    { question: 'Что угрожает безопасности России?' },
  ],
}

const sparkFunEn: QuestionSet = {
  categoryCode: 'spark-fun-en',
  categoryTitle: 'Spark fun',
  randomOrder: true,
  introText:
    "Hi! If you have interesting questions to ask someone, you also get them interested in you and what you have to say. Let's try!",
  questions: [
    {
      question:
        'Would you rather be in a real-life version of the walking dead or a real-life version of game of thrones?',
    },
    {
      question:
        "Would you rather have batman's skills, money, equipment, and lifestyle or end crime around the world for good but be poor and unnoticed?",
    },
    { question: 'How long would you last in a zombie apocalypse?' },
    { question: 'What "old person" things do you do?' },
    { question: "What did you think was cool then, when you were young but isn't cool now?" },
    {
      question: 'If you could know the absolute and total truth to one question, what question would you ask?',
    },
    { question: "What is the most unprofessional thing you've seen someone do?" },
    { question: 'You are about to get into a fight, what song comes on as your soundtrack?' },
    { question: "What is something that most people learn only after it's too late?" },
    { question: 'What is the best way the 1% could spend their money?' },
    {
      question: 'How many friends do you have on facebook and how many actually mean something to you?',
    },
    { question: 'Which of your personality traits has been the most useful?' },
    { question: 'How important are strong family ties to you?' },
    { question: 'If you had a theme song, what would it be?' },
    { question: 'If mars was livable, would you accept a one-way ticket there?' },
    { question: "What's a movie you can practically quote from start to finish?" },
    {
      question: 'If you could write one new law that everyone had to obey, what law would you create?',
    },
    { question: 'What do you like more, being a leader or a follower?' },
    {
      question:
        "What's one pet peeve of yours that you wish that you could get rid of because it hampers your enjoyment of life?",
    },
  ],
}

const firstDate: QuestionSet = {
  categoryCode: 'first-date',
  categoryTitle: 'Первое свидание',
  randomOrder: true,
  introText: 'Потренируемся в ответах на вопросы, которые могут быть заданы на первом свидании.',
  questions: [
    {
      question: 'Каким ты представляешь себе идеальное первое свидание?',
    },
    {
      question: 'Какой, по-твоему, самый романтичный город?',
    },
    {
      question: 'Ты считаешь себя романтиком?',
    },
    {
      question: 'Какая погода тебе кажется наиболее романтичной?',
    },
    {
      question: 'Ты веришь в дружбу между мужчиной и женщиной?',
    },
    {
      question: 'С каким заблуждением о себе ты сталкиваешься чаще всего?',
    },
    {
      question: 'Какой работой ты никогда не cмогла/смог бы заниматься?',
    },
    {
      question: 'Как ты встретил своего лучшего друга?',
    },
    {
      question: 'Есть что-то, что многие боятся делать, а ты нет?',
    },
    {
      question: 'Какое у тебя самое яркое воспоминание из детства?',
    },
    {
      question: 'Кого ты любишь больше, кошек или собак?',
    },
    {
      question: 'Ты жаворонок или сова?',
    },
    {
      question: 'Кто оказал наибольшее влияние на твою жизнь?',
    },
    {
      question: 'Какая у тебя цель в жизни прямо сейчас?',
    },
    {
      question: 'Какие сериалы тебе нравятся?',
    },
    {
      question: 'Ты любишь путешествовать? Расскажи про свое самое запоминающееся путешествие.',
    },
    {
      question: 'Как ты отдыхаешь после тяжелых рабочих дней?',
    },
    {
      question: 'Какой, по твоему мнению, должны быть роли женщины и мужчины в отношениях?',
    },
  ],
}

const aboutPartner: QuestionSet = {
  categoryCode: 'about-partner',
  categoryTitle: 'О партнере',
  randomOrder: true,
  introText: 'Вопросы о Вашем партнере, которые помогут Вам лучше понять Ваши отношения.',
  questions: [
    {
      question: 'Какую часть своей прошлой жизни Вы чаще обсуждаете со своим партнером?',
    },
    {
      question: 'Что сводит Вас с ума в Вашем партнере?',
    },
    {
      question: 'Какой лучший подарок Вы получали от партнера?',
    },
    {
      question: 'Вы полностью доверяете своему партнеру?',
    },
    {
      question: 'Что бы Вы сказали своему бывшему партнеру, если бы случайно встретили его?',
    },
    {
      question: 'Что бы Вы хотели сделать со своим любимым человеком, чего Вы никогда не делали?',
    },
    {
      question: 'Когда Ваши отношения были совершенно счастливыми?',
    },
    {
      question: 'Вы можете быть счастливымы только оттого, что счастлив Ваш партнер?',
    },
    {
      question: 'Кто в Ваших отношениях доминирует?',
    },
    {
      question: 'На какие уступки Вы идете, чтобы сохранить отношения с любимым человеком?',
    },
    {
      question: 'Два дня без партнера: как бы Вы хотели их провести?',
    },
    {
      question: 'Вы согласитесь, если Ваш партнер предложит Вам что-то необычное?',
    },
    {
      question: 'Кто в Ваших отношениях дизайнер интерьера?',
    },
    {
      question: 'Каким был Ваш первый год совместной жизни?',
    },
    {
      question: 'Вы любите гулять с партнером, держась за руки?',
    },
    {
      question: 'Какие приятные сюрпризы делал Ваш партнер?',
    },
    {
      question: 'Какие неприятные сюрпризы делал Ваш партнер?',
    },
    {
      question: 'Когда и как Вы поняли, что по-настоящему любите своего партнера?',
    },
    {
      question: 'Как сильно Ваш партнер влияет на Вас?',
    },
    {
      question: 'Кто в Вашей семье больше зарабатывает и как это сказывается на Ваших отношениях?',
    },
    {
      question: 'Кто оплачивает походы в рестораны, кино и т. д. в Вашей паре?',
    },
    {
      question: 'Вы со своим партнером из одной социальной среды?',
    },
    {
      question: 'Чего бы Вам хотелось, чтобы Ваш партнер делал почаще?',
    },
    {
      question: 'Как часто Вы устраиваете романтические вечера своему партнеру?',
    },
    {
      question: 'Вы со своим партнером были у семейного психолога? Считаете ли Вы это полезной практикой?',
    },
    {
      question: 'Как, по-Вашему, двое становятся единым целым?',
    },
    {
      question: 'Что может стать причиной для разрыва отношений?',
    },
    {
      question: 'Какой подарок от твоей второй половинки очень ценен для Вас?',
    },
    {
      question: 'Что нового Вы узнали о своем партнере за последние шесть месяцев?',
    },
    {
      question: 'Кто из Вас оплачивает отпуск?',
    },
    {
      question: 'Опишите своего идеального партнера.',
    },
    {
      question: 'Что Вы до сих пор скрываете от своего партнера?',
    },
    {
      question: 'Оправдались ли Ваши ожидания, касающиеся отношений с партнером?',
    },
    {
      question: 'Что самое плохое/хорошее произошло с Вашим нынешним возлюбленным?',
    },
    {
      question: 'Кто умнее - Вы или Ваш партнер?',
    },
    {
      question: 'Какие три вещи Вы любишь делать со своим партнером?',
    },
    {
      question: 'Кто в Ваших отношениях чаще принимает решение?',
    },
    {
      question: 'Какое первое впечатление произвел на Вас Ваш партнер?',
    },
    {
      question: 'Если мужчина пригласил женщину на ужин, должен ли он за нее платить?',
    },
  ],
}

const aboutLove: QuestionSet = {
  categoryCode: 'about-love',
  categoryTitle: 'О любви',
  randomOrder: true,
  introText: 'Поговорим на тему самого прекрасного чувства.',
  questions: [
    {
      question: 'Как часто Вы влюбляетесь?',
    },
    {
      question: 'Вы верите в любовь с первого взгляда?',
    },
    {
      question: 'Про кого Вы можете сказать, что он или она «любовь всей моей жизни»?',
    },
    {
      question: 'Какой Вы видите свою свадьбу?',
    },
    {
      question: 'Как часто Вы влюбляетесь?',
    },
    {
      question: 'Ваша любимая песня о любви?',
    },
    {
      question: 'Ваш любимый фильм о любви?',
    },
    {
      question: 'Что героического Вы сделали ради любви?',
    },
    {
      question: 'Кто был Вашей самой трудной любовной победой? Самой легкой?',
    },
    {
      question: 'Вас не смущают поцелуи на людях?',
    },
    {
      question: 'Какие знаки зодиака Вам больше всего подходят в качестве партнеров?',
    },
    {
      question: 'Вы когда-нибудь отбивали девушку/парня у своего друга/подруги?',
    },
    {
      question: 'Вы ревнивы?',
    },
    {
      question: 'Вы общаетесь со своими бывшими возлюбленными?',
    },
    {
      question: 'Вы когда-нибудь влюблялись более чем в одного человека?',
    },
    {
      question: 'Какой безумный поступок Вы совершали ради любви?',
    },
    {
      question: 'Вам нравится флиртовать?',
    },
    {
      question: 'Как бы Вы хотели, чтобы Ваш партнер проявлял свою любовь?',
    },
    {
      question: 'Вы знаете, как сложилась жизнь у Вашей первой любви?',
    },
    {
      question: 'Что такое любовь?',
    },
    {
      question: 'Почему люди пытаются изменить друг друга?',
    },
    {
      question: 'Опишите Ваши ощущения, когда Вы в первый раз влюбились?',
    },
    {
      question: 'Куда Вы пойдете знакомиться с людьми, если Вы будете одиноки?',
    },
    {
      question: 'Какие слова Вы бы хотели, чтобы Вам шептали на ухо?',
    },
    {
      question: 'Вы заходите на сайты знакомств?',
    },
    {
      question: 'Что Вас пугает в любовных отношениях?',
    },
    {
      question: 'Были ли Вы влюблены без взаимности?',
    },
    {
      question: 'Вы - фаталист?',
    },
    {
      question: 'Как Вы флиртуете и привлекаете к себе внимание?',
    },
    {
      question: 'Вам свойственно флиртовать, даже если Вы находитесь в обществе своего партнера?',
    },
    {
      question: 'Сколько свободы Вам необходимо в отношениях?',
    },
    {
      question: 'Какое существует самое неверное представление о любви, по Вашему мнению?',
    },
    {
      question: 'Если бы Вы любили кого-то, а он Вам предложил только дружбу, Вы бы согласились?',
    },
    {
      question: 'Как часто Вы говорите «я люблю тебя»?',
    },
    {
      question: 'Какие самые крепкие отношения были в Вашей жизни?',
    },
    {
      question: 'Каких сюрпризов Вы можете ожидать от любящих Вас людей?',
    },
    {
      question: 'Как людям следует выражать Вам их любовь?',
    },
    {
      question: 'За кем Вы готовы следовать долгое-долгое время?',
    },
    {
      question: 'Что хорошего в завязывании новых отношений?',
    },
    {
      question: 'Что плохого в завязывании новых отношений?',
    },
    {
      question: 'Ваше представление об идеальном мужчине/женщине?',
    },
    {
      question: 'Сколько раз в день Вам необходимо обниматься?',
    },
    {
      question: 'Кто, по-Вашему, самая романтическая пара в мировой истории, в книгах, кино?',
    },
    {
      question: 'Вспомни самый тяжелый разрыв, о котором Вы слышали.',
    },
    {
      question: 'Какое лучшее лекарство от любовной тоски?',
    },
    {
      question: 'Какова самая типичная причина разрыва отношений?',
    },
    {
      question: 'Как много времени надо, чтобы исцелить Ваше разбитое сердце?',
    },
    {
      question: 'По-Вашему, любовь освобождает человека или, наоборот, лишает его свободы?',
    },
    {
      question: 'Что зажигает в Вас огонь, а что тушит?',
    },
  ],
}

const aboutMarriage: QuestionSet = {
  categoryCode: 'about-marriage',
  categoryTitle: 'О свадьбе и браке',
  randomOrder: true,
  introText: 'Самое время обсудить свадебные вопросы.',
  questions: [
    {
      question:
        'Как Вы считаете, велика ли вероятность того, что брак будет удачным, если у обеих сторон этот брак первый?',
    },
    {
      question: 'Что самое лучшее есть в браке?',
    },
    {
      question:
        'Как Вы считаете, могут ли два человека очень сильно любить друг друга и при этом не иметь желания вступать в брак?',
    },
    {
      question: 'Опишите идеальный брак',
    },
    {
      question: 'Что Вы думаете о свободных браках?',
    },
    {
      question: 'Какова наиболее важная причина для брака?',
    },
    {
      question: 'Что Вы думаете о браке с человеком другой расы, национальности, вероисповедания и т.д.?',
    },
    {
      question: 'Какие иллюзии по поводу брака у Вас были до замужества/женитьбы?',
    },
    {
      question: 'Что Вам нравится в браке в большей, а что в меньшей степени?',
    },
    {
      question: 'Как Вы считаете, может ли быть какая-нибудь другая причина для брака, кроме любви?',
    },
    {
      question: 'Вы бы вступили бы в брак с человеком гораздо старше тебя? Младше?',
    },
    {
      question: 'Что является неотъемлемой составляющей счастливого брака?',
    },
    {
      question: 'Что является самой тяжелой стороной брака?',
    },
    {
      question: 'До какого возраста людям нежелательно вступать в брак?',
    },
    {
      question: 'Вы бы хотели заключить брачный контракт?',
    },
    {
      question: 'Куда бы Вы хотели отправиться в свой медовый месяц?',
    },
    {
      question: 'Опишите свою свадьбу или какой бы Вы хотели ее видеть.',
    },
    {
      question: 'Как Вы думаете, почему сегодня люди так часто разводятся?',
    },
  ],
}

const aboutIntimacy: QuestionSet = {
  categoryCode: 'about-intimacy',
  categoryTitle: 'Об интимной близости',
  randomOrder: true,
  introText: 'Поговорим про интимные вопросы. 18+.',
  questions: [
    { question: 'Вы смотрите порнографические фильмы?' },
    { question: 'Кто, по-Вашему, самый сексуальный из известных людей?' },
    { question: 'В каком возрасте Вы впервые занялись сексом?' },
    { question: 'Опишите Вашего первого сексуального партнера. Как это было в первый раз?' },
    { question: 'Как Вы считаете, когда наступает время в первый раз заняться сексом?' },
    { question: 'Вы хотели бы иметь больше или меньше сексуальных партнеров, чем у Вас есть на самом деле?' },
    { question: 'Как Вы относитесь к сексу на первом свидании?' },
    { question: 'Какую одежду Вы считаете сексуальной?' },
    { question: 'Как Вам больше всего нравится целоваться?' },
    { question: 'Как бы Вы поступили, если бы узнали, что Ваш партнер симулирует оргазм?' },
    { question: 'С каким известным человеком Вы бы хотели иметь сексуальные отношения?' },
    { question: 'Как Вы считаете, Вы хороший любовник?' },
    { question: 'Вы когда-нибудь занимались сексом по телефону?' },
    { question: 'Ты когда-нибудь занимался виртуальным сексом?' },
    { question: 'Где бы Вы хотели заняться любовью, кроме постели?' },
    { question: 'Что Вы любите делать после секса?' },
    { question: 'Вы когда-нибудь занимались любовью в опасном месте?' },
    { question: 'Что оживляет Ваши сексуальные отношения?' },
    { question: 'С кем Вы ни в коем случае не хотели бы заниматься сексом?' },
    { question: 'Изменились ли Ваши представления о сексе с годами?' },
    { question: 'Какая Ваша самая необычная сексуальная фантазия?' },
    { question: 'Какой самый большой срок, когда Вы не занимались сексом?' },
    { question: 'Вы бы хотели иметь друга, с которым могли бы время от времени заниматься сексом?' },
    { question: 'Должен ли быть закон, наказывающий неверных любовников?' },
    { question: 'Что для Вас означает интим?' },
    { question: 'Чувствуете ли Вы неловкость, когда смотрите фильм с друзьями, а на экране идет постельная сцена?' },
    { question: 'Что Вы предпочитаете: любовь без секса или секс без любви?' },
    { question: 'Какие слова Вы бы хотели, чтобы Вам шептали на ухо?' },
    { question: 'Вы согласитесь, если Ваш партнер предложит Вам что-то необычное?' },
    { question: 'Опишите Ваш первый поцелуй.' },
    { question: 'Вы покупаете презервативы в аптеке или заказываете по телефону/интернету?' },
    { question: 'Вам свойственно флиртовать, даже если Вы находишься в обществе своего партнера?' },
    { question: 'Как Вы флиртуете и привлекаете к себе внимание?' },
    { question: 'Сколько свободы Вам необходимо в отношениях?' },
    { question: 'Что делать, если один партнер хочет секса чаще, чем другой? И как это может повлиять на отношения?' },
    { question: 'Если у Вашего партнера обнаружится ВИЧ-инфекция, будете ли Вы продолжать с ним отношения?' },
    { question: 'Вы когда-нибудь покупали порнографическую литературу?' },
    { question: 'Должны ли любовь и секс следовать вместе?' },
    { question: 'У Вас когда-нибудь был секс на одну ночь?' },
    { question: 'Какую музыку Вы предпочитаете, когда занимаетесь любовью?' },
    { question: 'Хороший любовник - это?' },
    { question: 'В какое время суток Вам нравится заниматься любовью?' },
    { question: 'Что Вы делаете, когда интерес к партнеру гаснет?' },
    { question: 'Вы предпочитаете обычный секс?' },
    { question: 'Какое Ваше самое сильное сексуальное оружие?' },
    { question: 'Как часто Вы думаете о сексе?' },
    { question: 'Что для Вас важнее - секс или деньги?' },
    { question: 'Сколько у Вас было сексуальных партнеров?' },
    { question: 'Когда лучше всего первый раз заняться сексом с новым партнером?' },
  ],
}

const music: QuestionSet = {
  categoryCode: 'music',
  categoryTitle: 'Про музыку',
  randomOrder: true,
  introText: 'Поговорим о музыке.',
  questions: [
    { question: 'Какую музыку Вы любите?' },
    { question: 'Вы любите мюзиклы? (Назовите Ваш любимый.)' },
    { question: 'Вы часто слушаете музыку?' },
    { question: 'Вы когда-нибудь пели или играли в группе?' },
    { question: 'Какая песня действует на Вас завораживающе?' },
    { question: 'Посвящали ли Вы кому-нибудь песню по радио?' },
    { question: 'Какое самое яркое музыкальное представление Вы видели?' },
    { question: 'Какую музыку Вы предпочитаете слушать, когда Вы одни?' },
    { question: 'Вы предпочитаешь современную музыку или музыку прошедших лет?' },
    { question: 'Вы когда-нибудь брали уроки музыки?' },
    { question: 'Назовите три последних альбома, которые Вы послушали?' },
    { question: 'Если бы у Вас была своя рок-группа, как бы Вы ее назвали?' },
    { question: 'Какой музыкальный альбом Вы слушаете снова и снова и почему?' },
    { question: 'Какую песню Вы считаете самой романтичной?' },
    { question: 'Назовите самую нелепую, на Ваш взгляд, песню?' },
  ],
}

const movies: QuestionSet = {
  categoryCode: 'movies',
  categoryTitle: 'Про кино',
  randomOrder: true,
  introText: 'Поговорим о фильмах.',
  questions: [
    { question: 'Назовите пять своих любимых фильмов?' },
    { question: 'Какому актеру Вы хотели бы вручить «Оскар»?' },
    { question: 'Вы когда-нибудь снимали фильм на домашнее видео?' },
    { question: 'Вы смотрите сериалы?' },
    { question: 'Над каким фильмом Вы плакали в последний раз?' },
    { question: 'Что Вы предпочитаете - драму или комедию?' },
    { question: 'Как часто Вы ходите в кино?' },
    { question: 'Как Вы думаете, кого из современных актеров будут помнить спустя 100 лет?' },
    { question: 'Какие мюзиклы Вы знаете?' },
    { question: 'Какой самый скучный фильм Вам приходилось смотреть?' },
    { question: 'Во время просмотра каких фильмов Вы чаще всего плачете?' },
    { question: 'Какой Ваш самый любимый романтический фильм?' },
    { question: 'Вы любите фильмы ужасов?' },
    { question: 'Какой последний фильм Вы смотрели?' },
    { question: 'Какой псевдоним Вы бы взяли себе, если бы решили стать актером?' },
    { question: 'Кто, по-Вашему, самый красивый актер/актриса?' },
    { question: 'Какой фильм Вы смотрели бы снова и снова?' },
    { question: 'О чем бы Вы хотели снять фильм?' },
    { question: 'Как Вы относитесь к мыльным операм?' },
    { question: 'Какой киноперсонаж Вы бы могли отождествить с самими собой?' },
    { question: 'Вы любите смотреть видео или фильмы одни или с кем-то из близких?' },
    {
      question:
        'Вы любите делиться впечатлениями после просмотра фильма или предпочитаете оставлять впечатления при себе?',
    },
    { question: 'Кто самый романтичный певец на Ваш взгляд?' },
    { question: 'Альбомы какого исполнителя Вы слушаешь чаще всего?' },
  ],
}

const books: QuestionSet = {
  categoryCode: 'books',
  categoryTitle: 'Про книги',
  randomOrder: true,
  introText: 'Поговорим о книгах.',
  questions: [
    { question: 'Какую книгу Вы в последний раз читали и почему Вы выбрали именно ее?' },
    { question: 'Какие книги, по Вашему мнению, должны быть экранизированы?' },
    { question: 'Если бы Вы написали книгу, о чем бы она была?' },
    { question: 'Вы делаете пометки на полях, когда читаете книги?' },
    { question: 'Какую книгу Вы хотели бы прочитать еще раз?' },
    { question: 'Какой писатель оказал на Вас влияние?' },
    { question: 'Что Вы делаете с книгой, которую только что прочли?' },
    { question: 'Какие книги можно найти в Вашей домашней библиотеке?' },
    { question: 'Полное собрание сочинений какого автора прочитал Вы прочитали?' },
    { question: 'Если Вы напишете книгу, кому Вы ее посвятите?' },
    { question: 'Вы можете сейчас наизусть прочитать что-нибудь из Пушкина?' },
    { question: 'Какую книгу Вы бы взяли с собой на необитаемый остров?' },
    { question: 'Вы читаете книги медленно или быстро?' },
    { question: 'Где Вы любите читать?' },
    { question: 'Вы читали Библию?' },
  ],
}

const sport: QuestionSet = {
  categoryCode: 'sport',
  categoryTitle: 'Про спорт',
  randomOrder: true,
  introText: 'Поговорим о спорте.',
  questions: [
    { question: 'Роналду или Месси?' },
    { question: 'Футбол или хоккей?' },
    { question: 'Какой вид спорта Вам больше всего по душе?' },
    { question: 'Кто Вам любимый спортсмен?' },
    { question: 'Какой Ваш любимый спортивный клуб?' },
    { question: 'Шахматы - это спорт?' },
    { question: 'Вы занимаетесь спортом?' },
    { question: 'Спорт или диета?' },
    { question: 'Какой Ваш самый крутой личный спортивный рекорд?' },
    { question: 'Бег или качалка?' },
    { question: 'За какую футбольную команду Вы болеете?' },
    { question: 'Вы следите за Олимпийскими играми?' },
    { question: 'За что бы Вы хотели получить золотую медаль?' },
    { question: 'Где Вы предпочитаете плавать - в открытой воде или в бассейне?' },
    { question: 'Как Вы считаете, что в гольфе важнее - удача или тренировки?' },
    { question: 'Какой вид спорта по Вашему самый опасный?' },
    { question: 'Как Вы считаете, какое самое большое разочарование было за всю историю спорта?' },
  ],
}

const hobbies: QuestionSet = {
  categoryCode: 'hobbies',
  categoryTitle: 'Про развлечения и свободное время',
  randomOrder: true,
  introText: 'Поговорим о развлечениях и хобби.',
  questions: [
    { question: 'Все может быть смешным, если Вы хотите, чтобы это было смешным. Согласны ли Вы с этим утверждением?' },
    { question: 'Как именно Вы любите веселиться и беситься?' },
    { question: 'Какая самая тонкая шутка, которую Вы знаете?' },
    { question: 'Какой самый сумасшедший поступок Вы совершали в лесу?' },
    { question: 'Какой Ваш самый запомнившийся новогодний праздник?' },
    { question: 'Вы смогли бы жить без интернета?' },
    {
      question:
        'Если бы у Вас была возможность пользоваться интернетом только один час в неделю, что бы Вы выбрали для просмотра?',
    },
    { question: 'Что самое необычное Вы вытворяли с деньгами?' },
    { question: 'Какой Ваш самый смешной поступок?' },
    { question: 'Что Вы предпочитаете смотреть на YouTube?' },
    { question: 'Что Вам нравится в сегодняшнем телевидении и что не нравится?' },
    { question: 'Какие журналы Вы читаете?' },
    { question: 'Где и когда Вы в последний раз веселились от души?' },
    { question: 'Какие танцы Вы предпочитаете?' },
    { question: 'Назовите Вашу любимую компьютерную игру?' },
    { question: 'Вы любите караоке?' },
    { question: 'Если Вы пойдете на пляж, а он окажется нудистским, Вы останетесь или уйдете?' },
    { question: 'Вы любите ходить в театр?' },
    { question: 'Вы любите ходить по магазинам?' },
    { question: 'Вы поете, когда принимаете душ или ванну?' },
    { question: 'Какое Ваше самое забавное фото?' },
    { question: 'Какое самое смешное письмо или сообщение Вы получали и от кого?' },
    { question: 'Что Вы больше любишь в игре - процесс или результат?' },
    { question: 'Вам нравится балет?' },
    { question: 'Вы любите играть в карты?' },
    { question: 'Вы предпочитаете читать книги или слушать их?' },
    { question: 'На каком музыкальном инструменте Вы хотели бы научиться играть?' },
    { question: 'Вы любите поэзию?' },
    { question: 'Вам нравится черный юмор?' },
    { question: 'Вы делаете покупки, когда Вам что-то необходимо или Вы просто любите этот процесс?' },
    { question: 'Вы любите болтать по телефону?' },
    { question: 'Какая картина Вас трогает за живое?' },
    { question: 'Какие танцы были популярны во времена твоей молодости?' },
    { question: 'Вы любите разгадывать кроссворды?' },
    { question: 'Какой эффект оказывает на Вас реклама?' },
    { question: 'Вы умеете танцевать вальс?' },
    { question: 'По какому принципу Вы размещаете фотографии - по ситуациям или по датам?' },
    { question: 'Что Вы обычно делаете в одиночестве?' },
    { question: 'Вы пишете письма?' },
    { question: 'Что Вы делаете в дождливый субботний вечер?' },
    { question: 'Что для Вас значит «идеальный вечер»?' },
    { question: 'Вы когда-нибудь ходили на парады?' },
    { question: 'На что Вы в первую очередь обращаете внимание в музеях?' },
    { question: 'Что Вы делаете, чтобы расслабиться?' },
    { question: 'Вы хотели бы поучаствовать в каком-нибудь ток-шоу?' },
    { question: 'Если бы у Вас неожиданно оказалось два свободных часа, на что бы Вы их потратили?' },
    { question: 'Вам больше нравится смотреть видео или читать книги?' },
    { question: 'Какой вид искусства Вам нравится больше всего?' },
    { question: 'Если бы Вы были ведущим ток-шоу, кто был бы Вашим первым гостем?' },
    { question: 'Что Вы предпочитаете - Интернет или телевизор?' },
    { question: 'Вы читаете научно-популярные журналы?' },
    { question: 'Какой Ваш любимый веб-сайт?' },
    { question: 'Вы читаете газеты?' },
    { question: 'Назовите самое неудачное произведение искусства, на Ваш взгляд?' },
    { question: 'Вы хорошо поете?' },
    { question: 'Вы умеете свистеть?' },
    { question: 'Вы можете дотронуться кончиком языка до носа?' },
    { question: 'Если бы Вы нарисовали что-нибудь, что бы это было?' },
    { question: 'У Вас есть свой персональный вебсайт?' },
    { question: 'Что Вы обычно делаете воскресным теплым вечером?' },
    { question: 'Какие словечки Вы часто употребляете?' },
    { question: 'Вы любите читать автобиографии?' },
    { question: 'Какой знаменитости Вам бы хотелось дать пощечину?' },
    { question: 'Какой сувенир от известного лица Вы хотели бы получить?' },
    { question: 'С каким мультперсонажем Вы себя идентифицируете?' },
    { question: 'Кем бы Вы предпочли быть: известным певцом или известным писателем?' },
    { question: 'Что самое первое Вы создали своими руками?' },
    { question: 'Вы интересуетесь антиквариатом?' },
    { question: 'Вы ведете дневник?' },
    { question: 'Кем бы Вы предпочли быть: музыкантом, художником или писателем?' },
    { question: 'С каким сказочным персонажем Вы себя ассоциируете?' },
    { question: 'Назовите Ваши хобби. Делают ли они Вашу жизнь ярче?' },
    { question: 'Вы когда-нибудь прыгали с парашютом?' },
    { question: 'Вам нравится экстремальный отдых?' },
    { question: 'Где Вы предпочитаете отдыхать - в горах или на море?' },
    { question: 'Вы можете петь перед большой аудиторией?' },
    { question: 'Какой журнал Вы бы хотели видеть на любом журнальном столике?' },
    { question: 'Что Вы предпочитаете читать - глянцевые журналы или бестселлеры?' },
    { question: 'Кем бы Вы предпочли быть - первым среди последних или последним среди первых?' },
    { question: 'В соревнованиях Вы предпочитаете состязаться с равным соперником или с тем, кто слабее Вас?' },
    { question: 'Вы когда-нибудь катались на лошади?' },
    { question: 'Вы любите боулинг?' },
    { question: 'Вы когда-нибудь ловили рыбу?' },
    { question: 'Какое празднование дня рождения Вам особенно запомнилось?' },
    { question: 'Отличаются ли Ваши теперешние развлечения от развлечений пятилетней давности?' },
    { question: 'Что Вы способен сделать своими руками?' },
  ],
}

const interview: QuestionSet = {
  categoryCode: 'interview',
  categoryTitle: 'Собеседование',
  randomOrder: false,
  introText: 'Привет! Сейчас Вам будут предложены вопросы, которые могут встретиться на собеседовании на работу.',
  questions: [
    { question: 'Что Вы знаете о нашей компании?' },
    { question: 'Почему Вы хотите у нас работать?' },
    { question: 'Почему Вы интересуетесь именно этой позицией?' },
    { question: 'Что Вам больше всего нравится в нашей индустрии?' },
    { question: 'Что Вам меньше всего нравится в нашей индустрии?' },
    { question: 'Знаете ли Вы наших крупнейших конкурентов?' },
    { question: 'Почему Вы решили уйти с предыдущей работы?' },
    { question: 'Что является Вашими сильными и слабыми сторонами?' },
    { question: 'Что Ваш начальник с предыдущей работы сказал бы о Вас?' },
    { question: 'У Вас есть хобби? Расскажите о них.' },
    { question: 'Какие у Вас карьерные цели?' },
    { question: 'Кем Вы видите себя через 3 года? А через 10 лет?' },
    { question: 'Почему у Вас есть пробел в истории занятости?' },
    { question: 'Что Вас мотивирует?' },
    { question: 'Расскажите о ситуации, когда Вы проявили лидерские качества.' },
    { question: 'Расскажите о ситуации, когда Вы проявили инициативу.' },
    { question: 'Расскажите о ситуации, когда Вам пришлось улаживать конфликт.' },
    { question: 'Расскажите о ситуации, когда Вам пришлось столкнуться со сложным сотрудником или клиентом.' },
    { question: 'Расскажите о ситуации, когда Вы проявили навыки командной работы.' },
    { question: 'Расскажите о ситуации, когда Вы допустили ошибку. Как Вы ее уладили?' },
    { question: 'Расскажите про Ваше самое большое профессиональное достижение.' },
    { question: 'Расскажите как Вы представляете идеальную команду.' },
    { question: 'Расскажите про свою роль и вклад на предыдущем месте работы.' },
    { question: 'Какая у Вас сейчас зарплата?' },
    { question: 'Какие у Вас ожидания по зарплате от этой позиции?' },
    { question: 'Насколько готовы двигаться во Вашим финансовым ожиданиям? Готовы ли рассматривать меньшую зарплату?' },
    { question: 'У Вас есть какие-нибудь вопросы про нашу компанию?' },
  ],
}

const work: QuestionSet = {
  categoryCode: 'work',
  categoryTitle: 'Про работу',
  randomOrder: true,
  introText: 'Поговорим на темы, связанные с работой.',
  questions: [
    { question: 'Ты веришь в то, что люди делятся на лентяев и трудоголиков?' },
    { question: 'Что для тебя самое скучное в работе?' },
    { question: 'Над каким самым большим проектом тебе доводилось работать?' },
    { question: 'По каким параметрам ты определяешь, успешен человек или нет?' },
    {
      question:
        'Как ты думаешь, в чью пользу должен сделать выбор начальник между двумя равными по профессиональной подготовке людьми, но разнополыми?',
    },
    { question: 'Что было твоей первой настоящей работой?' },
    { question: 'Ты можешь переехать в другой город ради выгодной работы?' },
    { question: 'Что ты отвечал/отвечала, когда в детстве у тебя спрашивали: «Кем ты хочешь стать?»?' },
    { question: 'К каким профессиям ты относишься с презрением?' },
    { question: 'Ты видишь дискриминацию по половому или национальному признаку на работе?' },
    { question: 'Приходилось ли тебе работать во время учебы?' },
    { question: 'Каким ты видишь офис своей мечты?' },
    { question: 'Какой лучший комплимент по работе ты получал/получала?' },
    { question: 'Что может заставить тебя бросить работу?' },
    { question: 'Что самое важное для тебя в работе?' },
    { question: 'Важна ли протекция при устройстве на работу?' },
    { question: 'Какие задания по работе ты особенно ненавидишь?' },
    { question: 'Какие профессии ты хотел/хотела бы еще освоить?' },
    { question: 'Какую самую грязную работу тебе приходилось выполнять?' },
    { question: 'Как много сил ты отдаешь работе?' },
    {
      question: 'Как ты думаешь, должны ли одаренные подростки игнорировать высшее образование и открывать свое дело?',
    },
    { question: 'Поменял бы ты свою работу на более интересную, но менее оплачиваемую?' },
    { question: 'Будешь ли ты уважать начальника меньше, если он курит?' },
    { question: 'Как ты относишься к семьям, где жены зарабатывают больше мужей?' },
    { question: 'Людей каких необычных профессий ты знаешь?' },
    { question: 'С кем ты предпочитаешь работать - с мужчинами или женщинами?' },
    { question: 'В каких интересных местах тебе доводилось бывать по работе?' },
    { question: 'Ты предпочитаешь быть инициатором своей работы или любишь получать задания?' },
    {
      question: 'Если ты по работе сделаешь серьезную ошибку, то признаешь ли ты свою вину или попытаешься это скрыть?',
    },
    { question: 'Какую самую необычную работу тебе приходилось делать?' },
    { question: 'Типичный рабочий день. На что больше всего времени у тебя уходит?' },
    { question: 'О какой заработной плате ты мечтаешь?' },
    { question: 'Какими достижениями ты можешь гордиться?' },
    { question: 'Что бы тебе хотелось высказать своему боссу или сотрудникам?' },
    { question: 'Какую профессию ты назвал бы пагубной?' },
    { question: 'Расскажи историю о лучшем боссе.' },
    { question: 'Какая обстановка максимально располагает тебя к работе?' },
    { question: 'Какие изменения тебе хотелось бы видеть в своей компании?' },
    { question: 'Какие бизнес-персоны вызывают у тебя уважение?' },
    { question: 'Что тебе нравится больше: работать одному или в коллективе?' },
    { question: 'Как ты принимаешь комплименты на работе?' },
    { question: 'Сложно ли тебе попросить совет, если ты не знаешь, как поступить в трудной ситуации?' },
    { question: 'Ты практик или теоретик?' },
    { question: 'Что ты отвечаешь, когда тебя просят поработать сверхурочно?' },
    { question: 'Если женщина скажет мужчине, что он хорошо выглядит, будет ли это считаться сексуальным намеком?' },
    { question: 'Если мужчина скажет женщине, что она хорошо выглядит, будет ли это считаться сексуальным намеком?' },
    { question: 'Ты предпочитаешь физический труд или умственный?' },
    { question: 'Ты воспринимаешь работу, как бегство от семейных проблем?' },
    { question: 'Если бы ты жил 100 лет назад, какой работой ты хотел/хотела бы заниматься?' },
    { question: 'Какие корпоративные причуды раздражают тебя больше всего?' },
    { question: 'Расскажи про самый нелепый совет по работе, который тебе давали?' },
    { question: 'Расскажи про самый лучший совет по работе, который тебе давали?' },
    { question: 'Что должно стать для тебя причиной, чтобы не выйти на работу?' },
    { question: 'Какая профессия, по-твоему, будет популярна через 15 лет?' },
    { question: 'Что повлияло на выбор твоей профессии?' },
    { question: 'Что нужно для того, чтобы сделать работу более жизнерадостной?' },
    { question: 'Как часто ты ощущаешь, что кто-то красивее, успешнее, счастливее, богаче тебя?' },
    { question: 'Ты проявляешь инициативу или делаешь то, что тебе говорят?' },
  ],
}

const career: QuestionSet = {
  categoryCode: 'career',
  categoryTitle: 'Про карьеру',
  randomOrder: true,
  introText: 'Поговорим на темы, связанные с построением карьеры.',
  questions: [
    { question: 'С чего началась твоя карьера?' },
    {
      question: 'Если выбрать наугад 100 человек твоего возраста, как ты думаешь, сколько из них будут успешнее тебя?',
    },
    { question: 'Ты согласен с выражением: «Главное не победа, а участие»?' },
    {
      question:
        'Как ты считаешь, то место, которое человек занимает на социальной лестнице, отражает то, как он воспользовался своими возможностями?',
    },
    { question: 'По каким параметрам ты определяешь, успешен человек или нет?' },
    { question: 'Как ты думаешь, всегда ли успех - хорошо, а провал - плохо?' },
    { question: 'Что ты можешь сделать, чтобы стать лучшим в своем деле?' },
    { question: 'Каков твой рецепт успеха?' },
    { question: 'Какой должности ты хотел бы добиться там, где ты сейчас работаешь?' },
    { question: 'Чем ты готов заплатить за хорошую карьеру?' },
    { question: 'Как некомпетентные и безответственные люди умудряются сохранять руководящие должности?' },
    { question: 'Каким бизнесом ты бы хотел управлять?' },
    { question: 'У тебя есть призвание?' },
    { question: 'Как твое образование повлияло на твою карьеру?' },
    { question: 'Как ты относишься к тому, что люди не заводят детей из-за карьеры?' },
    { question: 'Кто помогал тебе в продвижении по карьерной лестнице?' },
    { question: 'Бывали ли в твоей карьере крупные неудачи?' },
    { question: 'Насколько высоко ты нацелился в своей карьере?' },
    { question: 'Ты часто мечтаешь о будущем?' },
    { question: 'Ты завидуешь людям, которые успешнее тебя?' },
    { question: 'В чем твое превосходство над другими?' },
    { question: 'Был ли ты для кого-нибудь наставником?' },
    { question: 'Кто был твоим наставником в жизни?' },
    { question: 'Ты согласен с мнением, что человеку необходимо пережить большую неудачу, чтобы стать закаленным?' },
    { question: 'Твои самые лучшие/худшие деловые решения? Как они повлияли на твою жизнь?' },
    { question: 'Как ты относишься к властным людям?' },
  ],
}

export default {
  pozner,
  dud,
  makarena,
  guriev,
  'a-pogovorit': aPogovorit,
  'russkie-norm': russkieNorm,
  'spark-fun-en': sparkFunEn,
  'first-date': firstDate,
  'about-partner': aboutPartner,
  'about-love': aboutLove,
  'about-marriage': aboutMarriage,
  'about-intimacy': aboutIntimacy,
  hobbies,
  movies,
  music,
  books,
  sport,
  interview,
  work,
  career,
}
