import React, { useState, useRef, useCallback, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import Webcam from 'react-webcam'
import Countdown from 'react-countdown'
import classNames from 'classnames'
import { springFadeInConfig } from '../../../utils/animation'
import { removeHasMediaPermissions } from '../../../utils/storage'
import { Question } from '../../../fixtures/questions'
import { Button } from '../../../design'
import { sendAnalyticsEvent } from '../../../utils/ga'

const COUNTDOWN_MLS = 3000

type AnswerRecorderProps = {
  question: Question
  mimeType: string
  onRecordingComplete: (recordedChunks: Blob[], duration: number) => void
  onSkipQuestion: () => void
}

const AnswerRecorder = ({ question, mimeType, onRecordingComplete, onSkipQuestion }: AnswerRecorderProps) => {
  const webcamRef = useRef(null)
  const mediaRecorderRef = useRef(null)
  const countdownWrapper = useRef(null)
  // useRef is used to provide mutable state to the handleDataAvailable function. See: https://stackoverflow.com/questions/53845595/wrong-react-hooks-behaviour-with-event-listener
  const recordStartTime = useRef<number>()
  const finalizeVideo = useRef(false)
  const recordedChunks = useRef<Blob[]>([])
  const springProps = useSpring(springFadeInConfig)

  const [duration, setDuration] = useState(0)
  const [hideRecorderAfterCompletion, setHideRecorderAfterCompletion] = useState(false)
  const [isRecording, setIsRecording] = useState(false)
  const [isUserMediaAvailable, setIsUserMediaAvailable] = useState(false)
  const [error, setError] = useState()

  const onVideoPlaying = useCallback(() => {
    setIsUserMediaAvailable(true)
  }, [])

  useEffect(() => {
    if (webcamRef?.current?.video) {
      webcamRef?.current?.video?.addEventListener('playing', onVideoPlaying)
    }

    return () => {
      if (webcamRef?.current?.video) {
        webcamRef?.current?.video?.removeEventListener('playing', onVideoPlaying)
      }
    }
  }, [webcamRef?.current?.video])

  useEffect(() => {
    let interval
    if (isRecording) {
      interval = setInterval(() => {
        setDuration(duration => duration + 1)
      }, 1000)
    }

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [isRecording])

  useEffect(() => {
    // reset state after skipped question
    if (isRecording) {
      setIsRecording(false)
      finalizeVideo.current = false
      setDuration(0)
      mediaRecorderRef.current.removeEventListener('dataavailable', handleDataAvailable)
      mediaRecorderRef.current.stop()
      recordedChunks.current = []
    }
  }, [question])

  const handleDataAvailable = useCallback(({ data }: { data: Blob }) => {
    if (data.size > 0) {
      const updatedRecordedChunks = recordedChunks.current.concat(data)
      recordedChunks.current = updatedRecordedChunks

      if (finalizeVideo.current) {
        const recordEndTime = Date.now()
        const duration = recordEndTime - recordStartTime.current
        onRecordingComplete(recordedChunks.current, duration)
      }
    }
  }, [])

  const onUserMediaError = useCallback(
    error => {
      setError(error)
      removeHasMediaPermissions()
    },
    [webcamRef, mediaRecorderRef]
  )

  const handleStopCaptureClick = useCallback(() => {
    sendAnalyticsEvent('click', { label: 'stop-recording' })
    mediaRecorderRef.current.stop()
    finalizeVideo.current = true
    // set this flag to hide recorder and prevent screen blinking
    setHideRecorderAfterCompletion(true)
  }, [mediaRecorderRef, webcamRef])

  if (error) {
    return (
      <div className="section-exercise__answer-recorder section-inner section-inner--m vertical-indent--xs">
        <h2>Не удалось получить доступ к камере или микрофону</h2>
        <p className="body-large refresh-page-message">Пожалуйста, обновите страницу</p>
        <Button
          buttonSize="large"
          onClick={() => {
            window.location.reload()
          }}
        >
          Обновить
        </Button>
      </div>
    )
  }

  const startRecording = () => {
    sendAnalyticsEvent('click', { label: 'start-recording' })
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType,
    })
    recordStartTime.current = Date.now()
    mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable)
    mediaRecorderRef.current.start()
    setIsRecording(true)
  }

  const countdownRenderer = ({ seconds }) => {
    let style
    if (webcamRef?.current?.video) {
      const { offsetWidth, offsetHeight } = webcamRef?.current?.video
      style = { width: offsetWidth, height: offsetHeight }
    }

    return (
      <div ref={countdownWrapper} className="countdown-wrapper" style={style}>
        {seconds}
      </div>
    )
  }

  const formatNumber = number => {
    return ('0' + number).slice(-2)
  }

  const answerRecorderSectionClass = classNames(
    'section-exercise__answer-recorder section-inner section-inner--m vertical-indent--xs',
    { 'section-exercise__answer-recorder--hidden': !isUserMediaAvailable || hideRecorderAfterCompletion }
  )

  const getButtons = () => {
    return (
      <>
        <Button className="action-button" buttonSize="large" onClick={handleStopCaptureClick} disabled={!isRecording}>
          Готово
        </Button>
        <Button buttonType="link" onClick={onSkipQuestion}>
          Другой вопрос
        </Button>
      </>
    )
  }

  return (
    <>
      {!isUserMediaAvailable && (
        <animated.div style={springProps}>
          <h1 className="section-exercise__loading-message">Загрузка...</h1>
        </animated.div>
      )}
      <div className={answerRecorderSectionClass}>
        <div className="recording-container">
          <Webcam mirrored={true} className="webcam-preview" height={150} ref={webcamRef} onUserMediaError={onUserMediaError} />
          {isUserMediaAvailable && !isRecording && (
            <Countdown date={Date.now() + COUNTDOWN_MLS} renderer={countdownRenderer} onComplete={startRecording} />
          )}
          <div className="recording-timer-container">
            {isRecording && (
              <>
                <div className="timer-wrapper">
                  {formatNumber(Math.floor(duration / 60))}:{formatNumber(duration % 60)}
                </div>
                <span className="recording-indicator">• Запись идёт</span>
              </>
            )}
          </div>
        </div>
        <h1 className="question">{question.question}</h1>
        {isUserMediaAvailable && getButtons()}
      </div>
    </>
  )
}

export default AnswerRecorder
