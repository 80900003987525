import React, { useState, useMemo } from 'react'
import { useSpring, animated } from 'react-spring'
import { navigate } from 'gatsby'
import classNames from 'classnames'
import ReactPlayer from 'react-player'
import { getIsSubscribedToUpdates } from '../../../utils/storage'
import plural from '../../../utils/plural'
import { springFadeInConfigWithoutReset } from '../../../utils/animation'
import { Button } from '../../../design'
import { Quote, SubscriptionModal } from '../..'
import { Question } from '../../../fixtures/questions'
import { Quote as QuoteType } from '../../../fixtures/quotes'
import './ResultStep.scss'

type ResultsStepProps = {
  answeredQuestions: Question[]
  skippedQuestions: Question[]
  videoAnswerURLs: string[]
  answerDurations: number[]
  mimeType: string
  quote: QuoteType
  onContinue: () => void
  noMoreQuestions: boolean
}

const ResultsStep = ({
  answeredQuestions,
  skippedQuestions,
  videoAnswerURLs,
  answerDurations,
  mimeType,
  quote,
  onContinue,
  noMoreQuestions,
}: ResultsStepProps) => {
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false)
  const [currentAnswerIndex, setCurrentAnswerIndex] = useState(0)
  const [isAnswerLinkClicked, setIsAnswerLinkClicked] = useState(false)
  const isSubscribed = useMemo(() => getIsSubscribedToUpdates(), [isSubscriptionModalOpen])
  const springProps = useSpring(springFadeInConfigWithoutReset)

  function toggleModal(open) {
    setIsSubscriptionModalOpen(open)
  }

  const averageDuration = useMemo(() => {
    const sum = answerDurations.reduce((a, b) => a + b, 0)
    const avg = sum / 1000 / answerDurations.length || 0
    return Math.round(avg)
  }, answerDurations)

  const answeredPercentage = useMemo(() => {
    return Math.round((answeredQuestions.length / (answeredQuestions.length + skippedQuestions.length)) * 100)
  }, [answeredQuestions, skippedQuestions])

  const onViewAnswer = index => {
    setCurrentAnswerIndex(index)
    setIsAnswerLinkClicked(true)
  }

  const generateAnswersList = () => {
    return answeredQuestions.map(({ question }, index) => {
      const answerLinkClass = classNames('answer-link', { active: currentAnswerIndex === index })

      return (
        <React.Fragment key={index}>
          <Button buttonType="link" className={answerLinkClass} key={index} onClick={() => onViewAnswer(index)}>
            {index + 1}. {question}
          </Button>
          <br />
        </React.Fragment>
      )
    })
  }

  const getContent = () => {
    return (
      <>
        <div className="player-wrapper">
          <ReactPlayer
            url={[{ src: videoAnswerURLs[currentAnswerIndex], type: mimeType }]}
            className="react-player"
            width="100%"
            height="100%"
            controls
            playing={isAnswerLinkClicked}
            webkit-playsinline="true"
            playsinline
          />
        </div>
        <h3>Ваши ответы</h3>
        <div className="section-result__answer-links">{generateAnswersList()}</div>
        <h3>Статистика ответов</h3>
        <div className="statistics">
          <div className="statistics__column">
            <h3 className="statistics__number">{`${averageDuration} ${plural(
              ['секунда', 'секунды', 'секунд'],
              averageDuration
            )}`}</h3>
            <p className="statistics__description body-large">Среднее время ответа на вопрос</p>
          </div>
          <div className="statistics__column">
            <h3 className="statistics__number">
              {answeredQuestions.length}/{answeredQuestions.length + skippedQuestions.length}
            </h3>
            <p className="statistics__description body-large">Вы дали ответы на {answeredPercentage}% вопросов</p>
          </div>
        </div>
        {/* <p>Пример совета:</p>
        <p>
          Скорее всего вы отвечаете на вопросы лаконично и быстро, это с одной стороны хорошо, так как вы не вдаетесь в
          детали и не мучаете собеседника долгим монологом. С другой, возможно иногда вам нужно задавать уточняющие
          вопросы, чтобы получить полный и интресный ответ.
        </p>
        <p>
          Вы ответили не на все вопросы, однако большая часть все же была осилена. Вы хороший собеседник и можете
          поддержать разговор на свободные или общие темы.
        </p> */}
        {/* <p className="quote-intro">И не забывайте:</p> */}
        <Quote quote={quote.quote} author={quote.author} />
      </>
    )
  }

  const sectionResultClass = classNames('section-result section-inner vertical-indent--xs section-inner--s', {
    'section-result--empty': answeredQuestions.length === 0,
  })

  return (
    <div className={sectionResultClass}>
      <animated.div style={springProps}>
        <h1>Результаты интервью</h1>
        {answeredQuestions.length > 0 ? (
          getContent()
        ) : (
          <p>Недостаточно данных для подсчета статистики и дачи рекомендаций</p>
        )}
        <SubscriptionModal isOpen={isSubscriptionModalOpen} toggleModal={toggleModal} />
        <div className="buttons-container">
          {/* {!isSubscribed && (
            <Button buttonType="link" className="subscribe-button" onClick={() => toggleModal(true)}>
              Подписаться на обновления
            </Button>
          )} */}
          {noMoreQuestions ? (
            <p className="section-result__no-more-questions body-medium">
              Вы ответили на все вопросы в данной категории
            </p>
          ) : (
            <Button buttonSize="large" onClick={onContinue}>
              Продолжить
            </Button>
          )}
          <Button buttonType="link" buttonSize="large" onClick={() => navigate('/categories')}>
            Выбрать другую категорию вопросов
          </Button>
        </div>
      </animated.div>
    </div>
  )
}

export default ResultsStep
